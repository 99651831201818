<template>
  <div id="app" class="mainScreen">
    <router-view></router-view>

    <div
      small
      fab
      style="background-color: #269fd7"
      shaped
      @click="back"
      class="backButton"
    >
      <v-icon style="color: white" size="30px">mdi mdi-chevron-left</v-icon>
    </div>

    <ScrollToTopButton />
  </div>
</template>

<script>
import ScrollToTopButton from "./components/common/basic/ScrollToTopButton.vue";

export default {
  name: "App",

  components: { ScrollToTopButton },
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>
<style>
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
    .v-input--has-state
  )
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #e9edf7;
  border-radius: 10px;
  font-family: Roboto !important;
}
.v-input--is-focused {
  border-radius: 10px !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 1370px) {
  ::-webkit-scrollbar-thumb {
    background: none !important;
    border-radius: 0px !important;
  }

  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
}
.mainScreen {
  position: relative;
}
.backButton {
  position: fixed;
  top: 50%;
  left: -10px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
</style>
