<template>
  <v-btn
    v-show="isVisible"
    fab
    small
    style="background-color: #269fd7"
    shaped
    @click="scrollToTop"
    class="scroll-to-top"
  >
    <v-icon style="color: white">mdi mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isVisible = window.scrollY > 200; // Show button after scrolling 200px
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.scroll-to-top {
  position: fixed;
  bottom: 10px;
  right: 5px;
  border-radius: 5px;
}
</style>
