import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "./helper/axios";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import DatetimePicker from "vuetify-datetime-picker";
import vueKanban from "vue-kanban";
import store from "./store";
import "./assets/style.css";
import "vue-swatches/dist/vue-swatches.css";
import "regenerator-runtime";

Vue.config.productionTip = false;

// DateTimePicker
Vue.use(DatetimePicker);

// kanban
Vue.use(vueKanban);

// Vueitfy
Vue.use(Vuetify);

// Setting The axios globally To access it from anywhere
Vue.prototype.$axios = axios;

// Server Url
Vue.prototype.API_BASE_URL = process.env.VUE_APP_UPLOAD_URL;

// WS plugin
import Ws from "@adonisjs/websocket-client";
import WsPlugin from "./socket_index";
Vue.use(WsPlugin, { adonisWS: Ws });
// end WS plugin

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
