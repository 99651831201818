const state = {
  allProjects: [],
  syncProjects: false,
  projectTeamMembers: [],
  projectLists: [],
  selectedListId: null,
  selectedListStatuses: [],
  projectTags: [],
  cachesTeamMembers: false,
  cachesStatusesList: false,
  cachesTags: false,
};

const mutations = {
  setProjectData(state, data) {
    state.allProjects = data;
  },
  setSyncProjects(state, data) {
    state.syncProjects = data.syncProjects;
  },
  setProjectTeamMembers(state, data) {
    state.projectTeamMembers = data;
  },
  setProjectLists(state, lists) {
    state.projectLists = lists;
  },
  setSelectedProjectListId(state, listId) {
    console.log("in store");
    state.selectedListId = listId;
  },
  setSelectedListStatuses(state, statuses) {
    state.selectedListStatuses = statuses;
  },
  setProjectTags(state, statuses) {
    state.projectTags = statuses;
  },
  isCachesTeamMembers(state, statuses) {
    state.cachesTeamMembers = statuses;
  },
  updateProjectCaches(state, { key, value }) {
    state[key] = value;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
