import Vue from 'vue'
import Vuex from 'vuex'
import search from './search'
import projects from './projects'
import employees from './employees'
import tasks from './tasks'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    search,
    projects,
    employees,
    tasks
  }
})
