import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

/**
 *
 *  Important : Do not change the route names because some functionalities like sync is depend on it
 *
 */

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../components/auth/Login"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () => import("../components/auth/ForgotPassword"),
  },
  {
    path: "/resetPassword/:token",
    name: "resetPassword",
    component: () => import("../components/auth/ResetPassword"),
  },
  {
    path: "/",
    component: () => import("../components/layouts/Layout"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("../components/Dashboard"),
      },
      {
        path: "/",
        redirect: "home",
      },
      {
        path: "employees",
        name: "employees",
        component: () => import("../components/employees/EmployeeList"),
      },
      {
        path: "timeSheet",
        name: "timeSheet",
        component: () => import("../components/timeSheet/TimeSheetList.vue"),
      },
      {
        path: "timeSheet/add",
        name: "timeSheet",
        component: () => import("../components/timeSheet/AddTimeSheet.vue"),
      },
      {
        path: "timeSheet/edit/:date",
        name: "timeSheet",
        component: () => import("../components/timeSheet/EditTimeSheet.vue"),
      },
      {
        path: "admin/timeSheet/add/:empId",
        name: "timeSheet",
        component: () =>
          import("../components/timeSheet/admin/AddTimeSheet.vue"),
      },
      {
        path: "admin/timeSheet/edit/:date/:empId",
        name: "timeSheet",
        component: () =>
          import("../components/timeSheet/admin/EditTimeSheet.vue"),
      },
      {
        path: "employees/employees-planning/:id",
        name: "employeePlanning",
        component: () => import("../components/employees/EmployeePlanning"),
      },
      {
        path: "employees/employee-details/:id",
        name: "employeeDetails",
        component: () => import("../components/employees/EmpDetails"),
      },
      {
        path: "projects",
        name: "projects",
        component: () => import("../components/projects/Index"),
      },
      {
        path: "archiveProjects",
        name: "archiveProjects",
        component: () => import("../components/projects/ArchiveProject"),
      },
      {
        path: "projects/:id/:active_tab?",
        name: "projectDetails",
        component: () => import("../components/projects/Project"),
      },
      {
        path: "/changePassword",
        name: "changePassword",
        component: () => import("../components/auth/ChangePassword"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("../components/auth/Profile"),
      },
      {
        path: "/setting",
        name: "setting",
        component: () => import("../components/auth/Setting"),
      },
      // {
      //   path: 'projects/:listId/board/:task_id?',
      //   name: 'kanban2',
      //   component: () => import('../components/kanban/Kanban2'),
      // },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  var isAuthenticated = false;

  if (localStorage.getItem("user") != null) {
    isAuthenticated = true;
  }

  if (
    to.name !== "login" &&
    to.name !== "resetPassword" &&
    to.name !== "forgotPassword" &&
    !isAuthenticated
  )
    next({ name: "login" });
  else next();

  if (
    (to.name == "login" && isAuthenticated) ||
    (to.name == "resetPassword" && isAuthenticated) ||
    (to.name == "forgotPassword" && isAuthenticated) ||
    (to.name == null && isAuthenticated)
  )
    next({ path: "/home" });
});

export default router;
