const state = {
  syncProject: false,
  tasksByListStatus: [],
  priorities: []
};

const mutations = {
  setSyncProject(state, data) {
    state.syncProject = data.syncProject;
  },
  setPriorities(state, data) {
    state.priorities = data;
  },
  setTasksByListStatus(state, data) {
    state.tasksByListStatus = data;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
