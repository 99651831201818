const state = {
  search: "",
};

const mutations = {
  setSearch(state, data) {
    state.search = data.search;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
