import axios from 'axios'
import router from '../router/index'
//  URL OF THE SERVER
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

let user = JSON.parse(localStorage.getItem('user'))

if(user)
{

  let baseURL = user.role == 'admin' ? `${process.env.VUE_APP_API_BASE_URL}/admin` : `${process.env.VUE_APP_API_BASE_URL}/api`
  axios.defaults.baseURL = baseURL
  axios.defaults.headers.common= {
    'Authorization' : 'Bearer '+ user.token
  }
}

axios.interceptors.response.use(
  (res) => {
    if (res.data.status == 403) {
      localStorage.removeItem('user');
      router.push("login");
    }
    return res;
  },
  (error) => {
    console.log('Axios error', process.env.VUE_APP_API_BASE_URL);
    console.log(error);
  }
);

export default axios